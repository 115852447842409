<template>
  <FocusTrap>
    <div id="mycard">
      <div class="card">

        <WindowTitleBar title="Holidays List" @loadData="loadHolidays" @close_window="closeThis">Calendar Year Holidays</WindowTitleBar>


<!--        <div class="card-header">-->
<!--          <div class="d-flex align-items-center" style="background-color: whitesmoke;">-->
<!--            <h3 class="mr-auto p-0">Calendar Year Holidays</h3>-->
<!--            <i class="icon-gear text-success-600"></i>-->
<!--          </div>-->
<!--        </div>-->

        <div class="card-body pt-1">
          <div class="d-lg-flex">
            <ul class="nav nav-tabs nav-tabs-vertical flex-column mr-lg-3 wmin-lg-200 mb-lg-0 border-bottom-0">
              <li class="nav-item"><a href="#vertical-left-tab1" class="nav-link active" data-toggle="tab"><i class="icon-calendar52 mr-2"></i>January</a></li>
              <li class="nav-item"><a href="#vertical-left-tab2" class="nav-link" data-toggle="tab"><i class="icon-calendar22 mr-2"></i>February</a></li>
              <li class="nav-item"><a href="#vertical-left-tab3" class="nav-link" data-toggle="tab"><i class="icon-calendar52 mr-2"></i>March</a></li>
              <li class="nav-item"><a href="#vertical-left-tab4" class="nav-link" data-toggle="tab"><i class="icon-calendar mr-2"></i>April</a></li>
              <li class="nav-item"><a href="#vertical-left-tab5" class="nav-link" data-toggle="tab"><i class="icon-calendar52 mr-2"></i>May</a></li>
              <li class="nav-item"><a href="#vertical-left-tab6" class="nav-link" data-toggle="tab"><i class="icon-calendar mr-2"></i>June</a></li>
              <li class="nav-item"><a href="#vertical-left-tab7" class="nav-link" data-toggle="tab"><i class="icon-calendar52 mr-2"></i>July</a></li>
              <li class="nav-item"><a href="#vertical-left-tab8" class="nav-link" data-toggle="tab"><i class="icon-calendar52 mr-2"></i>August</a></li>
              <li class="nav-item"><a href="#vertical-left-tab9" class="nav-link" data-toggle="tab"><i class="icon-calendar mr-2"></i>September</a></li>
              <li class="nav-item"><a href="#vertical-left-tab10" class="nav-link" data-toggle="tab"><i class="icon-calendar52 mr-2"></i>October</a></li>
              <li class="nav-item"><a href="#vertical-left-tab11" class="nav-link" data-toggle="tab"><i class="icon-calendar3 mr-2"></i>November</a></li>
              <li class="nav-item"><a href="#vertical-left-tab12" class="nav-link" data-toggle="tab"><i class="icon-calendar52 mr-2"></i>December</a></li>
            </ul>

            <div class="tab-content flex-lg-fill">
              <div class="tab-pane fade active show" id="vertical-left-tab1">
                <div class="card">
                  <div class="card-header text-primary-800 p-1" style="background-color: #1f53b8;color: whitesmoke;">
                    <h3 class="card-title" >January</h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table" id="tbl_January">
                        <thead>
                        <tr>
                          <th width="3%">S.No</th>
                          <th width="15%">Date</th>
                          <th>Occasion</th>
                          <th width="15%">Day</th>
                          <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, k) in january" :key="k">
                          <td></td>
                          <td>{{row.date}}</td>
                          <td>{{row.occasion}}</td>
                          <td>{{row.status}}</td>
                          <td class="text-center">
                            <div class="list-icons">
                              <div class="dropdown position-static">

                                <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-menu9"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" style="" >
                                  <a href="#" class="dropdown-item" @click="perform_holiday_delete(`${row.id}`)">
                                    <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-outline-success" @click="add_holiday">Add Holidays <i class="icon-add text-success-600"></i></button>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="vertical-left-tab2">
                <div class="card">
                  <div class="card-header text-primary-800 p-1" style="background-color: #1f53b8;color: whitesmoke;">
                    <h3 class="card-title" >February</h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table" id="tbl_February">
                        <thead>
                        <tr>
                          <th width="3%">S.No</th>
                          <th width="15%">Date</th>
                          <th>Occasion</th>
                          <th width="15%">Day</th>
                          <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, k) in february" :key="k">
                          <td></td>
                          <td>{{row.date}}</td>
                          <td>{{row.occasion}}</td>
                          <td>{{row.status}}</td>
                          <td class="text-center">
                            <div class="list-icons">
                              <div class="dropdown position-static">

                                <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-menu9"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" style="" >
                                  <a href="#" class="dropdown-item" @click="perform_holiday_delete(`${row.id}`)">
                                    <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-outline-success" @click="add_holiday">Add Holidays <i class="icon-add text-success-600"></i></button>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="vertical-left-tab3">
                <div class="card">
                  <div class="card-header text-primary-800 p-1" style="background-color: #1f53b8;color: whitesmoke;">
                    <h3 class="card-title" >March</h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table" id="tbl_March">
                        <thead>
                        <tr>
                          <th width="3%">S.No</th>
                          <th width="15%">Date</th>
                          <th>Occasion</th>
                          <th width="15%">Day</th>
                          <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, k) in march" :key="k">
                          <td></td>
                          <td>{{row.date}}</td>
                          <td>{{row.occasion}}</td>
                          <td>{{row.status}}</td>
                          <td class="text-center">
                            <div class="list-icons">
                              <div class="dropdown position-static">

                                <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-menu9"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" style="" >
                                  <a href="#" class="dropdown-item" @click="perform_holiday_delete(`${row.id}`)">
                                    <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-outline-success" @click="add_holiday">Add Holidays <i class="icon-add text-success-600"></i></button>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="vertical-left-tab4">
                <div class="card">
                  <div class="card-header text-primary-800 p-1" style="background-color: #1f53b8;color: whitesmoke;">
                    <h3 class="card-title" >April</h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table" id="tbl_April">
                        <thead>
                        <tr>
                          <th width="3%">S.No</th>
                          <th width="15%">Date</th>
                          <th>Occasion</th>
                          <th width="15%">Day</th>
                          <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, k) in april" :key="k">
                          <td></td>
                          <td>{{row.date}}</td>
                          <td>{{row.occasion}}</td>
                          <td>{{row.status}}</td>
                          <td class="text-center">
                            <div class="list-icons">
                              <div class="dropdown position-static">

                                <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-menu9"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" style="" >
                                  <a href="#" class="dropdown-item" @click="perform_holiday_delete(`${row.id}`)">
                                    <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-outline-success" @click="add_holiday">Add Holidays <i class="icon-add text-success-600"></i></button>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="vertical-left-tab5">
                <div class="card">
                  <div class="card-header text-primary-800 p-1" style="background-color: #1f53b8;color: whitesmoke;">
                    <h3 class="card-title" >May</h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table" id="tbl_May">
                        <thead>
                        <tr>
                          <th width="3%">S.No</th>
                          <th width="15%">Date</th>
                          <th>Occasion</th>
                          <th width="15%">Day</th>
                          <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, k) in may" :key="k">
                          <td></td>
                          <td>{{row.date}}</td>
                          <td>{{row.occasion}}</td>
                          <td>{{row.status}}</td>
                          <td class="text-center">
                            <div class="list-icons">
                              <div class="dropdown position-static">

                                <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-menu9"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" style="" >
                                  <a href="#" class="dropdown-item" @click="perform_holiday_delete(`${row.id}`)">
                                    <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-outline-success" @click="add_holiday">Add Holidays <i class="icon-add text-success-600"></i></button>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="vertical-left-tab6">
                <div class="card">
                  <div class="card-header text-primary-800 p-1" style="background-color: #1f53b8;color: whitesmoke;">
                    <h3 class="card-title" >June</h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table" id="tbl_June">
                        <thead>
                        <tr>
                          <th width="3%">S.No</th>
                          <th width="15%">Date</th>
                          <th>Occasion</th>
                          <th width="15%">Day</th>
                          <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, k) in june" :key="k">
                          <td></td>
                          <td>{{row.date}}</td>
                          <td>{{row.occasion}}</td>
                          <td>{{row.status}}</td>
                          <td class="text-center">
                            <div class="list-icons">
                              <div class="dropdown position-static">

                                <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-menu9"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" style="" >
                                  <a href="#" class="dropdown-item" @click="perform_holiday_delete(`${row.id}`)">
                                    <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-outline-success" @click="add_holiday">Add Holidays <i class="icon-add text-success-600"></i></button>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="vertical-left-tab7">
                <div class="card">
                  <div class="card-header text-primary-800 p-1" style="background-color: #1f53b8;color: whitesmoke;">
                    <h3 class="card-title" >July</h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table" id="tbl_July">
                        <thead>
                        <tr>
                          <th width="3%">S.No</th>
                          <th width="15%">Date</th>
                          <th>Occasion</th>
                          <th width="15%">Day</th>
                          <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, k) in july" :key="k">
                          <td></td>
                          <td>{{row.date}}</td>
                          <td>{{row.occasion}}</td>
                          <td>{{row.status}}</td>
                          <td class="text-center">
                            <div class="list-icons">
                              <div class="dropdown position-static">

                                <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-menu9"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" style="" >
                                  <a href="#" class="dropdown-item" @click="perform_holiday_delete(`${row.id}`)">
                                    <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-outline-success" @click="add_holiday">Add Holidays <i class="icon-add text-success-600"></i></button>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="vertical-left-tab8">
                <div class="card">
                  <div class="card-header text-primary-800 p-1" style="background-color: #1f53b8;color: whitesmoke;">
                    <h3 class="card-title" >August</h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table" id="tbl_August">
                        <thead>
                        <tr>
                          <th width="3%">S.No</th>
                          <th width="15%">Date</th>
                          <th>Occasion</th>
                          <th width="15%">Day</th>
                          <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, k) in august" :key="k">
                          <td></td>
                          <td>{{row.date}}</td>
                          <td>{{row.occasion}}</td>
                          <td>{{row.status}}</td>
                          <td class="text-center">
                            <div class="list-icons">
                              <div class="dropdown position-static">

                                <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-menu9"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" style="" >
                                  <a href="#" class="dropdown-item" @click="perform_holiday_delete(`${row.id}`)">
                                    <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-outline-success" @click="add_holiday">Add Holidays <i class="icon-add text-success-600"></i></button>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="vertical-left-tab9">
                <div class="card">
                  <div class="card-header text-primary-800 p-1" style="background-color: #1f53b8;color: whitesmoke;">
                    <h3 class="card-title" >September</h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table" id="tbl_September">
                        <thead>
                        <tr>
                          <th width="3%">S.No</th>
                          <th width="15%">Date</th>
                          <th>Occasion</th>
                          <th width="15%">Day</th>
                          <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, k) in september" :key="k">
                          <td></td>
                          <td>{{row.date}}</td>
                          <td>{{row.occasion}}</td>
                          <td>{{row.status}}</td>
                          <td class="text-center">
                            <div class="list-icons">
                              <div class="dropdown position-static">

                                <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-menu9"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" style="" >
                                  <a href="#" class="dropdown-item" @click="perform_holiday_delete(`${row.id}`)">
                                    <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-outline-success" @click="add_holiday">Add Holidays <i class="icon-add text-success-600"></i></button>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="vertical-left-tab10">
                <div class="card">
                  <div class="card-header text-primary-800 p-1" style="background-color: #1f53b8;color: whitesmoke;">
                    <h3 class="card-title" >October</h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table" id="tbl_October">
                        <thead>
                        <tr>
                          <th width="3%">S.No</th>
                          <th width="15%">Date</th>
                          <th>Occasion</th>
                          <th width="15%">Day</th>
                          <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, k) in october" :key="k">
                          <td></td>
                          <td>{{row.date}}</td>
                          <td>{{row.occasion}}</td>
                          <td>{{row.status}}</td>
                          <td class="text-center">
                            <div class="list-icons">
                              <div class="dropdown position-static">

                                <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-menu9"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" style="" >
                                  <a href="#" class="dropdown-item" @click="perform_holiday_delete(`${row.id}`)">
                                    <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-outline-success" @click="add_holiday">Add Holidays <i class="icon-add text-success-600"></i></button>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="vertical-left-tab11">
                <div class="card">
                  <div class="card-header text-primary-800 p-1" style="background-color: #1f53b8;color: whitesmoke;">
                    <h3 class="card-title" >November</h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table" id="tbl_November">
                        <thead>
                        <tr>
                          <th width="3%">S.No</th>
                          <th width="15%">Date</th>
                          <th>Occasion</th>
                          <th width="15%">Day</th>
                          <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, k) in november" :key="k">
                          <td></td>
                          <td>{{row.date}}</td>
                          <td>{{row.occasion}}</td>
                          <td>{{row.status}}</td>
                          <td class="text-center">
                            <div class="list-icons">
                              <div class="dropdown position-static">

                                <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-menu9"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" style="" >
                                  <a href="#" class="dropdown-item" @click="perform_holiday_delete(`${row.id}`)">
                                    <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-outline-success" @click="add_holiday">Add Holidays <i class="icon-add text-success-600"></i></button>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="vertical-left-tab12">
                <div class="card">
                  <div class="card-header text-primary-800 p-1" style="background-color: #1f53b8;color: whitesmoke;">
                    <h3 class="card-title" >December</h3>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table" id="tbl_December">
                        <thead>
                        <tr>
                          <th width="3%">S.No</th>
                          <th width="15%">Date</th>
                          <th>Occasion</th>
                          <th width="15%">Day</th>
                          <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, k) in december" :key="k">
                          <td></td>
                          <td>{{row.date}}</td>
                          <td>{{row.occasion}}</td>
                          <td>{{row.status}}</td>
                          <td class="text-center">
                            <div class="list-icons">
                              <div class="dropdown position-static">

                                <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-menu9"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" style="" >
                                  <a href="#" class="dropdown-item" @click="perform_holiday_delete(`${row.id}`)">
                                    <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-outline-success" @click="add_holiday">Add Holidays <i class="icon-add text-success-600"></i></button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      <FocusTrap>
        <vmodal name="holiday_window" draggable=".card" delay="100" resizable="true" width="450" :height="300"  >
          <HolidayWindow @holiday_save="perform_holiday_saved"></HolidayWindow>
        </vmodal>
      </FocusTrap>

    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import HolidayWindow from '../../../components/HolidayWindow'
import WindowTitleBar from '../../../components/core/WindowTitleBar'

export default {
  name: 'YearCalendar',
  components: { WindowTitleBar, HolidayWindow },
  store,
  data(){
    return {
      january:[],
      february:[],
      march:[],
      april:[],
      may:[],
      june:[],
      july:[],
      august:[],
      september:[],
      october:[],
      november:[],
      december:[],
      holidays:[],
      holiday : JSON.parse('{"id":0,"status":"WorkingDay","type":0,"date":"","occasion":""}'),
    }
  },
  mounted () {
    this.loadHolidays();
  },
  methods:{
    add_holiday() {
      try {
        this.$modal.show('holiday_window');
      }catch (e) {
        alert(e);
      }
    },
    closeThis(){
      this.$router.push("/");
    },
    loadHolidays(){
      const self = this;
      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.holidays = [];
      self.$data.january = [];
      self.$data.february = [];
      self.$data.march = [];
      self.$data.april = [];
      self.$data.may = [];
      self.$data.june = [];
      self.$data.july = [];
      self.$data.august = [];
      self.$data.september = [];
      self.$data.october = [];
      self.$data.november = [];
      self.$data.december = [];

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });
      // alert(`${process.env.VUE_APP_ROOT_API}api/hr/holidays/year/${moment(store.state.user.working_date).format('YYYY')}`);
      fetch(`${process.env.VUE_APP_ROOT_API}api/hr/holidays/year/${moment(store.state.user.working_date).format('YYYY')}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          // self.$data.holidays = resp.data;

          resp.data.forEach((row)=>{
            switch (moment(row.date).format('MM')) {
              case '01':
                self.$data.january.push(row);
                break;
              case '02':
                self.$data.february.push(row);
                break;
              case '03':
                self.$data.march.push(row);
                break;
              case '04':
                self.$data.april.push(row);
                break;
              case '05':
                self.$data.may.push(row);
                break;
              case '06':
                self.$data.june.push(row);
                break;
              case '07':
                self.$data.july.push(row);
                break;
              case '08':
                self.$data.august.push(row);
                break;
              case '09':
                self.$data.september.push(row);
                break;
              case '10':
                self.$data.october.push(row);
                break;
              case '11':
                self.$data.november.push(row);
                break;
              case '12':
                self.$data.december.push(row);
                break;
            }
          });

        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });
    },
    build_row(row){
      return `<tr>
                <td></td>
                <td>${row.date}</td>
                <td>${row.occasion}</td>
                <td>${row.status}</td>
                <td class="text-center">
                    <div class="list-icons">
                        <div class="dropdown position-static">
                            <a href="#" class="list-icons-item" data-toggle="dropdown" aria-expanded="false">
                                <i class="icon-menu9"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" style="">
                              <a href="#" class="dropdown-item" @click="alert('Welcome');" >
                                <i class="icon-cancel-circle2 text-warning-600"></i> Remove
                              </a>
                            </div>
                        </div>
                    </div>
                </td>
             </tr>`;
    },
    perform_holiday_saved(day) {
      const self = this;
      this.$modal.hide('holiday_window');

      const requestOptions = {
        method:  'POST',
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify( day )
      };

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });
      fetch(`${process.env.VUE_APP_ROOT_API}api/hr/holiday`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer:3000} );
          self.$data.holiday = JSON.parse('{"id":0,"status":"WorkingDay","type":0,"date":"","occasion":""}');
          self.loadHolidays();
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: `failed to load data!`, type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });

    },
    perform_holiday_delete(id) {
      const self = this;

      const requestOptions = {
        method:  'DELETE',
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify( {"id":id,"status":"WorkingDay","type":0,"date":"","occasion":""} )
      };

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });
      fetch(`${process.env.VUE_APP_ROOT_API}api/hr/holiday`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer:3000} );
          self.$data.holiday = JSON.parse('{"id":0,"status":"WorkingDay","type":0,"date":"","occasion":""}');
          self.loadHolidays();
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: `failed to load data!`, type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });

    },
  }
}
</script>

<style >
/*https://infoheap.com/css-automatic-table-row-numbering/*/
table {
  /*width: 50%;*/
  counter-reset: row-num;
}
table tbody tr {
  counter-increment: row-num;
}
table tbody tr td:first-child::before {
  content: counter(row-num) " ";
}
</style>
